import IState from 'services/state';
import { clearPendingPageDoc, clearPendingThemesDocs, clearRegionRendererDraft, resetPendingAdminChange, setDraftTheme, setPreviewActiveTheme, writeLegacySuccess } from './actions';
import { call, put, select } from 'redux-saga/effects';
import { getMockThemeRenderer, getPendingAdminChanges, getPendingPageDoc, getPendingThemesDocs, getPreviewActiveTheme, getRegionRendererDrafts } from './selectors';
import { getObject, getParentAndChildSlug, getSite, getSiteId } from 'services/app';
import { CHANNEL_PATH_MAP, clearPendingDataSaga, handlePageWriteError, mergeObject, saveErrorSaga, shouldUpdateObject, SITE_PATH_MAP, writeDocSaga } from './saga';
import { getPath } from 'services/app-router/selectors';
import { replace } from 'services/app-router';
import { applyTheme, getSavedTheme, setForkedTheme } from 'services/themes';
import { getPrimaryToken } from 'services/auth';
import { upsertTheme } from 'services/themes/api';
import { ITheme } from 'models';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { setDocument } from './api';
import { updatePageInNavigations } from 'services/navigationv2';

export const publishPendingPage = function* () {
  const state: IState = yield select();
  const pendingPageDoc = getPendingPageDoc(state);
  const currentDoc = getObject(state);
  const { parentSlug, childSlug } = getParentAndChildSlug(state);
  const pendingChanges = getPendingAdminChanges(state);
  const regionDrafts = getRegionRendererDrafts(state);

  if (!pendingPageDoc && !Object.keys(regionDrafts).length) {
    return;
  }

  const pendingDoc = structuredClone(pendingPageDoc || currentDoc);

  try {
    Object.entries(regionDrafts).forEach(([rendererId, renderer]) => {
      if (!pendingDoc.activeRenderers) {
        pendingDoc.activeRenderers = {};
      }
      if (!pendingDoc.renderers) {
        pendingDoc.renderers = {};
      }
      pendingDoc.activeRenderers[rendererId] = true;
      pendingDoc.renderers[rendererId] = renderer;
    });

    const pathName = childSlug ? `${parentSlug}/${pendingDoc.slug}` : `/${pendingDoc.slug}`;
    yield call(writeDocSaga, 'objects', pendingDoc);

    const shouldUpdateNavigations = (
      (currentDoc.slug && currentDoc.slug !== pendingDoc.slug) ||
      (currentDoc.data?.name && currentDoc.data.name !== pendingDoc.data.name) ||
      (currentDoc.data?.private && currentDoc.data.private !== pendingDoc.data.private)
    );
    if (shouldUpdateNavigations) {
      yield put(updatePageInNavigations(pendingDoc._id, pendingDoc.data.name!, pendingDoc.slug, pendingDoc.data.private));
    }

    yield put(clearPendingPageDoc(pendingDoc._id));

    for (const rendererId of Object.keys(regionDrafts)) {
      yield put(clearRegionRendererDraft(rendererId));
    }

    for (const key of Object.keys(pendingChanges)) {
      if (CHANNEL_PATH_MAP[key]) {
        yield put(resetPendingAdminChange(key as keyof typeof CHANNEL_PATH_MAP));
      }
    }

    const existingPath = getPath(state);
    if (existingPath !== pathName) {
      yield put(replace({ path: pathName }));
    }
  } catch (error) {
    yield call(handlePageWriteError, error);
  }
};

export const publishPendingThemes = function* () {
  const state: IState = yield select();
  const themesDocs = getPendingThemesDocs(state);
  const mockThemeRenderer = getMockThemeRenderer(state);
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);

  try {
    // if user preview a theme before hits publish
    const previewActiveTheme = getPreviewActiveTheme(state);
    if (previewActiveTheme) {
      yield put(applyTheme({ theme: previewActiveTheme }));
      yield put(setPreviewActiveTheme(null));
    }

    if (!Object.keys(themesDocs).length) {
      return;
    }

    let currentEditableThemeUpdated: ITheme | null = null;

    for (const [id, themeDoc] of Object.entries(themesDocs)) {
      if (id && themeDoc) {
        const isDraftFromMock = mockThemeRenderer?._id === id;
        const upsertedTheme: ITheme = yield call(upsertTheme, {
          id,
          primaryToken: primaryToken!,
          siteId,
          theme: themeDoc,
        });

        if (isDraftFromMock) {
          currentEditableThemeUpdated = upsertedTheme;
        }
      }
    }

    const theme = getSavedTheme(state);
    if (currentEditableThemeUpdated) {
      yield put(setForkedTheme({ theme: null }));
      yield put(setDraftTheme(null));
      const shouldAskToActivateTheme = theme._id !== currentEditableThemeUpdated._id;

      if (shouldAskToActivateTheme) {
        yield put(
          showModal({
            data: {
              theme: currentEditableThemeUpdated,
            },
            kind: ModalKinds.activateThemeConfirmation,
          }),
        );
      }
    }
    yield put(clearPendingThemesDocs());
  } catch (error) {
    yield call(saveErrorSaga, error, 'Admin themes upsert error');
  }
};

export const publishPendingSite = function* () {
  const state: IState = yield select();
  const unpublishedChanges = getPendingAdminChanges(state);
  const site = getSite(state);
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  const pendingChanges = getPendingAdminChanges(state);

  try {
    const shouldUpdateSiteObject = yield call(shouldUpdateObject, SITE_PATH_MAP, unpublishedChanges);
    if (!shouldUpdateSiteObject) {
      return;
    }

    const updatedSiteObject = mergeObject(
      site,
      unpublishedChanges,
      SITE_PATH_MAP,
    );

    yield call(setDocument, {
      collection: 'sites',
      id: siteId,
      primaryToken: primaryToken!,
      siteId,
      value: updatedSiteObject,
    });

    for (const key of Object.keys(pendingChanges)) {
      if (SITE_PATH_MAP[key]) {
        yield put(resetPendingAdminChange(key as keyof typeof SITE_PATH_MAP));
      }
    }

  } catch (error) {
    yield call(saveErrorSaga, error, 'Admin site upsert error');
  }
};

const publishSaga = function* () {
  yield call(publishPendingSite);
  yield call(publishPendingThemes);
  yield call(publishPendingPage);
  yield call(clearPendingDataSaga);
  yield put(writeLegacySuccess());
};

export default publishSaga;
